import { Injectable, Renderer2, RendererFactory2 } from "@angular/core";


@Injectable({
    providedIn: "root",
})

export class SeoSchemaValidator {

    private renderer: Renderer2;

    constructor(renderFoctory: RendererFactory2) {
        this.renderer = renderFoctory.createRenderer(null, null);
    }

    addSchemaMarkup(schema: any): void {
        const existingScripts = document.querySelectorAll('script[type="application/ld+json"]');
        existingScripts.forEach(script => script.remove());
        const script = this.renderer.createElement('script');
        script.type = 'application/ld+json';
        script.text = JSON.stringify(schema);
        document.head.appendChild(script);
    }
}